//
// Base -> Typography
//
// Use this file for site-wide typographic
// adjustments, including web fonts and headings.
// --------------------------------------------------


body * {
    font-family: effra, sans-serif;
    letter-spacing: 0.5px;

    p,
    ul li {
        color: $c-slate;
    }
}


h1, .h1,
h1 span, .h1 span {
    @include heading(1);
    font-family: $headings-font-family;
    color: $c-moonlight-teal;
    font-weight: 600;

    @media (min-width: $screen-md-min) {
        font-size: 42px;
        line-height: 48px;
    }
}

h2, .h2 {
    @include heading(2);


    @media (min-width: $screen-md-min) {
        font-size: 28px;
        line-height: 34px;
    }
}

h3, .h3 {
    @include heading(3);

    @media (min-width: $screen-md-min) {
        font-size: 22px;
        line-height: 27px;
    }
}

h4, .h4 {
    @include heading(4);


    @media (min-width: $screen-md-min) {
        font-size: 18px;
        line-height: 23px;
    }
}

h5, .h5 {
    @include heading(5);


    @media (min-width: $screen-md-min) {
        font-size: 16px;
        line-height: 20px;
    }
}

.h2, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
    font-weight: normal;
    color: $c-moonlight-teal;
    font-family: effra, sans-serif;
}

p.narrow,
p.intro,
p.narrow.intro {
    font-weight: 300;
    font-size: 18px;
    color: #101820;
    font-weight: 800;
    margin-bottom: 50px;
}

.email-link:before,
.fax-link:before,
.html-block .email-link:before,
.html-block .fax-link:before,
.html-block .tel-link:before,
.html-block .web-link:before,
.html-block a.email-link:before,
.html-block a.fax-link:before,
.html-block a.tel-link:before,
.html-block a.web-link:before,
.tel-link:before,
.web-link:before,
a.email-link:before,
a.fax-link:before,
a.tel-link:before,
a.web-link:before {
    border: none;
    top: 11px;
    margin-right: 0;
}

.html-block a {
    text-decoration: none;
    color: $c-button-light-blue;
    font-weight: bold;

    &:hover,
    &:active {
        text-decoration: underline;
        color: $c-button-light-blue;
    }
}

.html-block a.btn-default:hover {
    text-decoration: none !important;
}

.html-block a.btn-primary:hover {
    text-decoration: none !important;
}

.primary-nav__link.hidden-xs {
    font-family: effra,sans-serif;
}

.tertiary__link {
    font-family: effra,sans-serif;
}