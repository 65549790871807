.primary-nav__item.dropdown > a:after,
.language-selector__selected:after {
    content: '';
    background: url(/assets/images/nav-dropdown-uk.png) no-repeat 0 0;
 
}

.primary-nav__link.has-children {
      @media(max-width: $screen-sm-max){
           background: url(/assets/images/nav-dropdown-uk-mobile.png) no-repeat 100% 50%;
    }   
}