.blog,
.full-width .blog {

    &__cta {
        position: relative;
        box-shadow: none;
    }

    &__author {
        background-color: $c-off-white;
    }

    &__cta-title a,
    &__cta-title a.muted-link {
        color: #006385;
        text-decoration: none;
    }

    &__cta-body {

        .read-more,
        .read-more-toggle {
            color: #006385;
            font-weight: 700;
            box-shadow: 0 2px 0 -1px #006385;
            padding-bottom: 2px;
            text-decoration: none;
        }

        .read-more:after {
            content: ">";
            background: none;
            padding-left: 12px;
            color: #006385;
        }
    }
}
.blog__author.blog__author--detail {
    background-color: $c-springsky;
}
.blog .blog__author.profile-details-contact-details, 
.blog__author-contact-details, 
.full-width .blog .blog__author.profile-details-contact-details, 
.full-width .blog__author-contact-details {
    padding-top: 0;
    a{
        float: right;
    }
    > .blog__author-email {
        margin-bottom: 20px;
        color: #fff;
    }
}

.blog__author p {
    color: $c-midnight-moon;
}


.email-link.blog__author-email,
.tel-link.blog__author-tel {
    background-color: transparent;
    padding: 0;
    font-weight: 400;
    color: $c-blog-profile-link !important;

    &:hover,
    &:active,
    &:focus {
        background-color: inherit !important;
        border: none;
        outline: none;
        color: $c-blog-profile-link !important;
        text-decoration: underline;
    }

    &:after {
        display: none;
    }
}

.blog__cta-body, .full-width .blog__cta-body {
    color: #101820;
}

.blog__author-details__heading a.muted-link {
    color: $c-advisor-profile-link;

    &:hover, &:focus {
        text-decoration: underline;
    }
}

.blog__author-contact-ethos-heading {
	margin-bottom: 0px;
}

.blog__author-contact-ethos-subheading {
	font-style: italic;
}
