div.hero-banner.hero-banner--uk /*,
.carousel*/ {
    position: relative;
    background-position: 50% 0 !important;
    background-size: 100%;
    padding-top: 52%;
    height: auto;

    @media(min-width: $screen-sm-min) {
        background-position: center !important; /* Try disabling parallax directly instead */
        background-size: cover;
        min-height: 330px;
        z-index: 1;
        padding-top: 0;
    }

    @media(min-width: 1440px) {
        background-size: cover;
        height: 330px; // Remove, referenced above
        padding-top: 0;
    }

    @media(min-width: 1920px) {
        overflow: visible;
    }

    a.link-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        display: inline-block;
        width: 100%;
    }
    // Creates a coloured block beofre and after so that on very large screens the image is centered and bg colour is applied - RM
    /*&:before,
    &:after {
        @media(min-width: 1920px) {
            content: "";
            display: block;
            position: absolute;
            background-color: $c-moonlight-teal;
            top: 0;
            bottom: 0;
            width: calc((100vw - 1440px) / 2);
        }
    }*/
    &:before {
        @media(min-width: 1920px) {
            left: calc((100vw - 1440px) / -2);
        }
    }

    &:after {
        @media(min-width: 1920px) {
            right: calc((100vw - 1440px) / -2);
        }
    }

    > .container {
        background-color: transparent;
        width: 100%;
        padding: 15px;

        @media(min-width: 768px) {
            padding: 0 !important;
        }
    }

    &.hero-banner__overlay--uk {
        text-align: left;


        &:before {
            display: none;
        }

        .btn.btn-primary {


            @media(min-width: $screen-sm-min) {
                bottom: -30%;
                top: auto;
                left: -10px;
            }
        }
    }
}

.highlight--small,
.hero-text > .head,
.hero-banner__overlay p.narrow,
.hero-banner__overlay p,
.hero-text > .highlight {
    color: white;
    font-weight: 200;
}

//Testimonials Carousel

.carousel.testimonials {
    margin-bottom: 30px;
    min-height: 230px; //Needed to override the deafult carousel container height - Urrghh - RM
    .carousel__inner {
        background: transparent;
    }

    .carousel__item:focus {
        outline: none;
    }

    .slick-next {
        background: url(/assets/images/carousel-right-arrow.png) no-repeat 50% !important;
    }

    .slick-prev {
        background: url(/assets/images/carousel-left-arrow.png) no-repeat 50% !important;
    }

    .slick-dots {

        @media(max-width:$screen-xs-max) {
            bottom: 20px !important;
        }

        li button:before {
            border: 2px solid white;
        }
    }
}



.testimonialCarousel {
    min-height: 400px !important; //Needed to override the deafult carousel container height - Urrghh - RM
    @media(min-width: $screen-md-min) {
        min-height: 350px !important; //Needed to override the deafult carousel container height - Urrghh - RM
    }

    > .container {
        height: 100%;
        padding: 0 !important;
        margin: 0 !important;
        width: 100%;
    }

    &__innerWrapper {
        background: $c-moonlight-teal;
        display: flex;
        height: 100%;
        min-height: 400px;
        font-size: 20px;
        font-family: effra,sans-serif;
        position: relative;
        font-size: 16px;

        @media(min-width: $screen-md-min) {
            min-height: 350px;
        }
    }

    &__profileImg {
        background-size: cover;
        display: none;
        width: 100%;
        height: auto;
        position: relative;
        background-position: center;

        @media(min-width: $screen-sm-min) {
            display: flex;
            flex: 5;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: -108px;
            left: auto;
            z-index: 0;
            width: 220px;
            background: $c-moonlight-teal;
            transform: skew(155deg);
        }
    }

    &__profileText {
        z-index: 1;
        //font-family: effra,sans-serif;
        flex-direction: column;
        justify-content: center;
        vertical-align: middle;
        display: flex;
        text-align: center;
        padding: 40px 15%;
        width: 100%;

        @media(min-width: $screen-md-min) {
            padding: 0px 10% 0 0;
            text-align: left;
            flex: 5;
        }

        p {
            margin-bottom: 0;
        }
    }


    &__quoteText {
        quotes: '"' '"';
        position: relative;
        color: white;
        width: 100%;
        padding: 0;
        line-height: 0;
        display: block;
        line-height: 1.3;
        padding-bottom: 25px !important;
        margin: 0 auto;
        justify-content: center;

        @media(min-width: $screen-sm-min) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            padding: 0 35px;
        }

        &:before,
        &:after {
            color: white;
            font-size: 60px;
            font-style: italic;
            position: absolute;
            // display: none;
        }

        &:before {
            content: open-quote;
            left: -25px;
            top: -30px;
            bottom: auto;

            @media(min-width: $screen-sm-min) {
                left: 0px;
            }
        }

        &:after {
            content: close-quote;
            right: -10px;
            bottom: -35px;
            top: auto;

            @media(min-width: $screen-md-min) {
                right: 10px;
            }
        }
    }

    &__jobTitle,
    &__office {
        color: white;
        display: block;
        text-align: center;
        width: 70%;
        margin: 0 auto;
		font-weight: bold;

        @media(min-width: $screen-md-min) {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            text-align: left;
            padding-left: 30px;
        }

        @media(min-width: $screen-md-min) {
            padding-left: 35px;
        }
    }
    //Profile with no image
    &__innerWrapper.no-img {
        .testimonialCarousel__profileText {
            margin: 0 auto;
            width: 100%;
            padding: 40px 15%;

            @media(min-width: $screen-md-min) {
                padding: 80px 15%;
            }

            &:before,
            &:after {
                content: "";
                border-color: #0a161e transparent;
                border-style: solid;
                border-width: 200px 90px 0 0;
                height: 0;
                width: 0;
                position: absolute;
                top: 0;
                display: block;
            }

            &:before {
                left: 0;
                bottom: auto;
                top: 0;
            }

            &:after {
                transform: rotate(180deg);
                right: 0;
                bottom: 0;
                top: auto;
            }

            .testimonialCarousel__quoteText {
                margin: 0 auto;
                padding-bottom: 25px;
                z-index: 2;

                @media(min-width: $screen-md-min) {
                    margin: 0;
                }
            }
        }
    }
}


//IE Specific overrides


