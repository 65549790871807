﻿.crumb__link:after,
.main-content .crumb__link:after {
    content: url(/assets/images/crumb-chevron.png);
    display: inline-block;
    margin: 0 5px;
    color: $c-midnight-moon;
    font-size: 19px;
    position: relative;
    top: 2px;
    font-weight: 500;
}

.crumb__link,
.main-content .crumb__link {
    color: $c-breadcrumb;
    font-size: 13px;


    &--active {
        color: $c-left-nav-active;
    }
}
