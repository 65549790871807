.tooltip,
.calculator-widget .tooltip {
    &.tooltip.right .tooltip-arrow {
        border-right-color: $c-buttonblue;

        @media (max-width: $screen-xs-max) {
            right: 0;
            left: auto;
            border-width: 5px 0 5px 5px;
            border-left-color: $c-buttonblue;
        }
    }

    @media (max-width: $screen-xs-max) {
        &.right {
            .tooltip-arrow {
                border-left-color: $c-buttonblue;
            }
        }
    }

    &-inner {
        background-color: $c-buttonblue;
        opacity: 10 !important;
    }
}

.btn-tooltip,
.calculator-widget .btn-tooltip {
    background-color: $c-buttonblue;
    color: white;
    border-radius: 50%;
    padding: 0;
    appearance: none;
    height: 30px;
    width: 30px;
    border: none;
    position: absolute;
    right: 20px;
    font-family: serif;
    font-size: 23px;
    text-align: center;
    bottom: 6px;

    @media (min-width: $screen-sm-min) {
        top: 0;
        bottom: auto;
    }


    &--xs-bottom {
        @media (max-width: $screen-xs-max) {
            & + .tooltip.in {
                top: 100%;
                left: auto;
                right: auto;

                .tooltip-arrow {
                    right: 50%;
                    left: auto;
                    top: auto !important;
                    border-width: 0 5px 5px 5px;
                    border-bottom-color: $c-buttonblue;
                    border-left-color: transparent;
                    border-right-color: transparent;
                }
            }
        }
    }
}
