.main-content .cta {
	/*margin-bottom: 30px;*/
	h2 {
		color: white;
		font-weight: 500;
		font-size: 30px;
		font-family: $font-family-sans-serif;
	}

	&.cta-wideblue .cta__title,
	&.cta-lightgrey .cta__title,
	&.cta--green .cta__title,
	&.cta--royalblue .cta__title {
		font-family: $font-family-sans-serif;
		font-size: 20px;
	}

	&.cta--lightgrey {
		background-color: $cta-lightgrey-bg;
		text-align: left;


		.cta__body {
			padding: 30px;

			h3,
			h2 {
				color: $c-slate;
				font-size: 25px;
			}

			p,
			ul li {
				font-size: 16px;
				margin-bottom: 5px;
				color: $c-slate;
			}

			span.homepage-stats {
				color: $c-slate;
				font-size: 42px;
				font-weight: bold;
			}

			a,
			a:not(.btn) {
				color: $c-buttonblue;
				cursor: pointer;
			}
		}
	}
	//CTA Standard
	&.cta--standard,
	&.cta--bordered {
		border: none;
		box-shadow: none;

		h2 {
			color: $c-moonlight-teal !important;
			font-weight: 500;
		}

		p {
			color: $c-slate;
		}

		.cta__body {
			border: 2px solid $c-moonlight-teal;
		}
	}

	&.cta--royalblue {
		background: $c-midnight-moon;

		img {
			float: right;
			width: 100%;
			max-width: 135px;
			height: auto;
		}

		p,
		ul li {
			color: #fff;
		}

		ul {
			margin-bottom: 25px;
		}
		//Contact us page specific - RM
		&.cta--contact-us {

			p {
				font-size: 20px;
			}

			h3,
			.email-link a {
				color: #fff;
				word-break: break-all;
			}

			a:hover {
				cursor: pointer;
			}

			h3 {
				margin-bottom: 0;
			}

			a.email-link:before {
				background: url(/assets/images/email-icon-borderless-white.png) no-repeat -2px 35%;
			}
		}
	}

	&.cta--green {
		background: $c-midnight-moon;

		h3 {
			color: white;
		}

		p,
		p.narrow {
			color: white;
			font-weight: 400;
		}
	}

	&__body {
		padding: 30px;
	}

	&--bg {

		p {
			color: white;
		}
	}

	&.cta--wideblue {

		.cta__content {
			padding-top: 15px;
		}
	}

	&.cta--bg-no-mob {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
		position: relative;
		z-index: 10;
		color: $cta-bg-no-mob;
		padding: 20px 15px;
		border: 1px solid transparent;

		@media (min-width: $screen-sm-min) {
			padding: 0;
		}

		@media (min-width: $screen-md-min) {
			padding: 0;
		}

		@media (min-width: $screen-lg-min) {
			padding: 0;
		}

		&:before {
			content: "";
			display: block;
			position: relative;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $cta-bg-overlay-color;
			z-index: -1;
		}

		.cta-body {
			.cta_title {
				line-height: 1.2;
				color: $cta-text-light;

				@media (min-width: $screen-sm-max) {
					color: $cta-bg-no-mob;
				}
			}

			@media (max-width: $screen-md-min) {
				border: 2px solid $cta-bg-no-mob;

				.cta_title {
					color: $cta-bg-no-mob;
				}
			}

			@media (min-width: $screen-md-min) {
				position: absolute;
				top: 30px;
				padding: 50px;
				width: 40%;
				border: none;

				.cta_title {
					line-height: 1.2;
					color: $cta-text-light;
				}

				p {
					color: $cta-text-light;
					margin-bottom: 60px;
				}
			}

			@media (min-width: $screen-lg-min) {
				position: absolute;
				top: 30px;
				padding: 50px;
				width: 40%;
				border: none;

				.cta_title {
					line-height: 1.2;
				}

				p {
					color: $cta-text-light;
					color: $cta-text-light;
					margin-bottom: 60px;
				}
			}
		}

		@media (min-width: $screen-sm-min) {
			padding: $cta-padding-sm-min;
		}
	}
}


.cta-tiles {
    &__container {

        .pull {
            margin-top: -100px;

            @media(max-width:$screen-sm-max) {
                margin-top: 0;
            }
        }
    }

    &__pod {
        padding: 20px;
        color: #fff;
        border-radius: 4px;
        margin-bottom: 20px;
        min-height: 200px;

        h3 {
            margin-bottom: 5px;
            color: #fff;
            font-weight: 500;
            font-size: 22px;
        }

        &.tall {
            min-height: 300px;
        }
    }
}

.blog__cta.transactions {
    box-shadow: none;

    .blog__summary .more {
        color: $c-main-text;

        &:hover {
            text-decoration: underline;

            &:after {
                content: ">";
                display: inline-block;
                margin-left: 5px;
                font-size: 16px;
                font-weight: normal;
                position: relative;
                top: 1px;
            }
        }
    }

    .blog__cta-img-bg {
        width: 100%;
        height: auto;
        text-align: center;
        position: relative;
        padding: 17px;
        padding-bottom: 12px;

        img {
            max-width: 100%;
            margin: 0 auto;
        }

        span.hr {
            height: 1px;
            width: 100%;
            background-color: #dad9d6;
            margin: 0 auto;
            display: inline-block;
        }
    }

    .blog__cta-body, .full-width .blog__cta-body {
        padding: 0 17px 5px;
        color: $c-navbar-bg;

        .blog__cta-title {
            color: $c-navbar-bg;
        }

        .read-more {
            color: $c-navbar-bg;
            width: 100%;
            display: block;
            box-shadow: none;
            position: relative;
            margin-bottom: 15px;

            &:after {
                content: "";
                width: 20px;
                height: 15px;
                background: url(/assets/images/capital-markets/chevron.png) no-repeat 10px 0;
                display: inline-block;
                position: absolute;
                top: 0;
                right: 0;
                background-size: contain;
            }
        }

        .blog__summary {
            margin-bottom: 37px;
        }

        .blog__info {
            font-weight: bold;
            color: $c-navbar-bg;
        }
    }

    .sector__container {
        background-color: $c-top-nav;
        padding: 17px;
        color: #fff;

        p {
            border-bottom: 1px solid #fff;
            padding-bottom: 13px;
            font-size: 13px;

            &:last-of-type {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        .cross-border {
            position: relative;
            display: block;
            width: 100%;

            &:after {
                content: '';
                width: 25px;
                height: 25px;
                background: url(/assets/images/capital-markets/gears.png) no-repeat 0 0;
                display: inline-block;
                background-size: cover;
                position: absolute;
                right: 0;
                bottom: 0;

                @media(min-width:$screen-sm-min) and (max-width:$screen-sm-max) {
                    right: -15px;
                }
            }
        }
    }
}

.footnote__container {

    .cross-border {
        position: relative;
        left: 40px;
        display: block;

        &:before {
            content: '';
            width: 30px;
            height: 30px;
            background: url(/assets/images/capital-markets/gears.png) no-repeat 0 0;
            display: inline-block;
            background-size: cover;
            position: absolute;
            left: -37px;
            bottom: -7px;
        }
    }

    .footnote-item {
        padding-left: 5px;
    }
}


a.btn.btn-primary.sector-btn {
    width: 100%;
    max-width: 323px;
    display: inline-block;
    margin: 0 0 15px 0;
    background-color: $c-top-nav;
    border-radius: 0;
    position: relative;
    text-align: left;

    &:after, &:hover:after {
        content: "";
        width: 10px;
        height: 15px;
        background: url(/assets/images/capital-markets/chevron-white.png) no-repeat 4px 1px;
        display: inline-block;
        position: absolute;
        top: 25%;
        right: 20px;
        background-size: contain;
        border-left: none;
    }

    &:hover:after {
        display: none !important;
    }
}


.form-cta-container .main-content form.form,
.main-content .form-cta-container form.form {
    border: 2px solid $c-moonlight-teal;
    box-shadow: none;
    background-color: white;

    h2 {
        font-weight: 500;
        color: $c-moonlight-teal;
    }
    /*.btn.btn-primary,
    .btn.btn-default {
        width: 100%;
    }*/
}
