.header {
    &-bottom {
        header.header & {
            .primary-nav {
                &_mobile-logo {
                    max-height: 50px;
                }
                @media (min-width: $screen-sm-min) {
                    width: 90%;
                    &__list {
                        text-align: left;
                    }
                }
                //prevents the secondary nav getting cut off at small desktop and up to 1370px (bespoke breakpoint for this functionality only)
                @media (min-width: $screen-sm-min) and (max-width: 1370px) {
                    &__item:nth-of-type(2) .primary-nav__panel {
                        transform: translateX(0);
                        margin-left: 0;
                        .primary-nav__item::after {
                            left: 10%;
                        }
                    }
                }
            }
        }
    }

    &-top {
        header.header & {
            .header__logo {
                > img {
                    height: auto;
                    @media (min-width: $screen-sm-min) {
                        width: 130px;
                    }
                }
            }
        }
    }
}
