﻿.main-content .left-nav, .left-nav {

    &__item {
        display: block;


        &--parent > a {
            color: $c-link-visited-grey;
            font-weight: 600;

            &:after {
                position: absolute;
                right: 0;
                top: -3px;
                content: url(/assets/images/left-nav-parent-active_UK.png);
                display: inline-block;
            }
        }
    }

    &__link {
        color: $c-left-nav-active;

        &--active {
            color: $c-link-visited-grey;

            &:after {
                content: url(/assets/images/left-nav-active_UK.png);
                display: inline-block;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        &:hover, &:focus {
            color: $c-moonlight-teal;
            text-decoration: underline;
        }
    }

    &__lower {
        padding-left: 20px;
    }
}
