//
// Base -> Variables
//
// Use this file to customise Bootstrap variables
// ahead of compilation.
// (See vendor/bootstrap/_variables.scss)
// --------------------------------------------------

$imgPath: '/assets/images/';

// Colour Palette Colours

$c-navbar-bg: #695e4a;
$borderColor: #fff;
$c-brand-orange: $c-orange;
$c-moonlight-teal: #006385;
$bgColor: #da8659;
$c-lock-btn-Bgcolour: #fff;
$c-brand-dark-blue: #00234c;
$c-mid-grey: #b1b4b5;
$c-link-visited-grey: #6C7375;
$c-white: #fff;


$c-midnight: #191718;
$c-midnight-moon: #293244;
$c-midnight-rain: #0a161e;
$c-main-nav-bg: #231f20;
$c-darkblue: $c-main-nav-bg;
$c-silver-bullett: #b5ccd9;
$c-left-nav-active: #006385;
$c-slate: #5e97b0;
$c-grape: #293244;
$c-lavendar: #005596;
$c-lightLavendar: #cdd9e7;
$c-buttonblue: #008099;
$c-button-light-blue: #00b5ef;
$c-paleblue: #04bfd8;
$c-near-black: #040404;
$c-off-white: #f0f0f0;
/*$header-background-color: $c-white;
$header-bottom-background-color: rgba(255, 255, 255, 0.8);*/
/*$main-search-background-color: $header-bottom-background-color;*/

$tooltip-opacity: 10 !default;

$c-hr: #d9dad5;
$c-btn-primary: $c-orange;
$c-btn-secondary: $c-lightblue;

$c-btn-default: $c-lightblue;

$borderColor: white;

$c-document-download-link: $c-lightblue;

$c-heading: #80a1b6;
$c-main-text: #695e4a;
$c-top-nav: #676c73;
$c-advisor-profile-link:  #49697f;
$c-blog-profile-link: #49697f;
$c-footer-link: #fff;
$c-breadcrumb: #495b67;

$c-grey-opaque: rgba(118, 122, 128, 0.7490196078431373);

$c-lighter-slate: #81888e;
$c-slate: #62656A;
$c-lightslate: #547992;
$c-light-slate: #547992;
$c-tabs-primary: #005596;
$c-tabs-secondary: #80a1b6;
$c-tabs-tertiary: #2e2b27;

$show-left-nav-arrows: true;
$show-cta-heading-arrows: false;
$show-read-more: true;
$header-background-color: $c-lightblue;


// CTA Specific
$cta-standard-bg: white;
$cta-lightgrey-bg: $c-disclaimer-bg;
$cta-royalblue-bg: $c-royalblue;
$cta-green-bg: $c-green;
$cta-bordered-bg: white;

$cta-padding: 30px 34px;
$cta-padding-reduced: 24px 20px;
$cta-padding-sm-min: 45px 36px;

$cta-text-light: white;
$cta-text-dark: $text-color;
$cta-text-heading-light: $cta-text-light;
$cta-text-heading-dark: $c-heading;

$cta-green-text: $cta-text-light;
$cta-royalblue-text: $cta-text-light;
$cta-lightgrey-text: $cta-text-dark;
$cta-lightgrey-heading-text: $cta-text-dark;

$cta-bottom-margin: 30px;
$cta-homepage-stats-size: 42px;
$cta-equalise-bottom-margin: 15px;
$cta-bg-overlay-color: rgba(0, 0, 0, 0.2);

$cta-bordered-border: 1px solid #dad9d6;
$cta-bordered-text: $text-color;

$cta-standard-heading-color: $c-lightblue;

$cta-has-box-shadow: true;
$cta-box-shadow: 0 5px 10px 4px rgba(0,0,0,.12);

//Aditional Varibales for Visual Identity changes 2019 - RM
$footer-bottom-background-color: $c-mid-grey;
$footer-top-background-color: $c-moonlight-teal;
$header-background-color: $c-moonlight-teal;
$header-bottom-background-color: $c-main-nav-bg;
$main-search-background-color: $c-main-nav-bg;
$c-lightblue: $c-moonlight-teal;

