.modal-dialog {
    overflow-y: auto;
    margin: auto t-spacing(4);
    max-height: 60vh;
    top: 20%;
    @media (min-width: $screen-sm-min) {
        margin: auto;
        max-height: 50vh;
        top: 25%;
    }
}
