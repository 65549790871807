.main-content form.form {
	background-color: #cdd9e7;
	box-shadow: none;
  
	.Form__Element__Caption {
	  color: #231f20;
	}
  
	.form-tooltip {
	  display: none;
	}

	[data-tooltip-content-id] {
		display: none;
	}
  
	// New contact form styling - namespaced under data attribute
	&.EPiServerForms[data-epiforms-metadata="Enhanced"] {

	  $-form-field-border-color: #53565a;
	  $-form-field-caption-text-color: #050505;
	  $-form-field-placeholder-text-color: #65676b;
	  $-form-field-error-color: #d5442a;
	  $-form-tooltip-color: #293244;
  
	  background-color: $c-white;
	  box-shadow: none;
	  padding: 25px 0;
  
	  fieldset {
		border: none;
  
		@media (max-width: $screen-xs-max) {
		  width: 100%;
		}
	  }
  
	  legend {
		&.el-lgd-investment-services-option,
		&.el-lgd-are-you-an-intermediary,
		&.el-lgd-preferred-contact {
		  width: auto;
		  float: left;
		}
  
		&.el-lgd-are-you-an-intermediary,
		&.el-lgd-preferred-contact {
		  & + button + label,
		  & + label {
			& + label {
			  clear: none;
			  margin-left: 16px;
			}
		  }
		}
	  }
  
	  .clearfix {
		&::before,
		&::after {
		  opacity: 0;
		  border: none;
		}
	  }
  
	  .Form__Element__Caption {
		color: $-form-field-caption-text-color;
		border-bottom: none;
		margin-bottom: 16px;
		display: inline-block;
		min-width: 0;
  
		@media (max-width: $screen-xs-max) {
		  max-width: 85%;
		}
	  }
  
	  .Form__Element {
		margin: 0 0 0.5rem 0;
  
		&.FormChoice {
		  fieldset {
			display: inline;
		  }
		}
  
		.Form__Element__ValidationError {
		  color: $-form-field-error-color;
		  font-size: 10px;
		  margin-top: -20px;
		}
	  }
  
	  .form-tooltip {
		width: 20px;
		height: 20px;
		line-height: 40px;
		vertical-align: top;
		text-align: center;
		background: $-form-tooltip-color;
		color: #fff;
		border-radius: 50%;
		margin-left: 10px;
		margin-top: 2px;
		font-weight: 700;
		cursor: pointer;
		position: relative;
		outline: none;
		border: none;
		line-height: 1em;
		padding: 0;
		display: inline-block;
  
		&.el-btn-investment-services-option,
		&.el-btn-financial-planning-services-option,
		&.el-btn-working-with-us-option {
		  margin-bottom: 20.7px;
		}
  
		&.el-btn-preferred-contact,
		&.el-btn-are-you-an-intermediary {
		  float: left;
  
		  + label {
			margin-bottom: 15px;
  
			+ label {
			  margin-bottom: 15px;
			}
		  }
		}
  
		&.tooltip-open {
		  @media (max-width: $screen-xs-max) {
			&::before {
			  content: "";
			  display: block;
			  position: fixed;
			  width: 100%;
			  height: 100%;
			  background-color: rgba(0, 0, 0, 0.5);
			  left: 0;
			  top: 0;
			  z-index: 2;
			}
		  }
		}
	  }
  
	  .form-tooltip-close-icon {
		position: relative;
		float: right;
		width: 40px;
		height: 40px;
		display: none;
  
		&::after {
		  content: "\00d7";
		  color: black;
		  font-size: 40px;
		  position: absolute;
		  top: 0;
		  left: 0;
		  top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%) !important;
		}
  
		@media (max-width: $screen-xs-max) {
		  display: block;
		}
	  }
  
	  .form-tooltip-text {
		background-color: $-form-tooltip-color;
		line-height: 24px;
		position: absolute;
		padding: 8px 15px;
		width: max-content;
		max-width: 400px;
		min-width: 200px;
		text-align: left;
		border-radius: 2px;
		font-weight: normal;
		left: calc(100% + 20px);
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
  
		p,
		div,
		span,
		h1,
		h3,
		h4,
		h5,
		h6,
		ul,
		li {
		  color: white;
		}
  
		&::after {
		  content: "";
		  display: block;
		  width: 0;
		  height: 0;
		  border-top: 8px solid transparent;
		  border-right: 8px solid $-form-tooltip-color;
		  border-bottom: 8px solid transparent;
		  border-left: 8px solid transparent;
		  position: absolute;
		  left: -16px;
		  top: 50%;
		  transform: translateY(-50%);
		}
  
		@media (max-width: $screen-xs-max) {
		  bottom: 0;
		  left: 0;
		  width: 100vw;
		  padding: 24px 16px;
		  z-index: 3;
		  transform: none;
		  background-color: white;
		  color: black;
		  position: fixed;
		  max-width: none !important;
		  width: 100%;
		  border-radius: 8px 8px 0px 0px;
		  overflow-x: scroll;
  
		  p,
		  div,
		  span,
		  h1,
		  h3,
		  h4,
		  h5,
		  h6,
		  ul,
		  li {
			color: black;
		  }
  
		  &::after {
			content: none;
		  }
		}
	  }
  
	  label {
		color: $-form-field-caption-text-color;
  
		&.el-lbl-investment-services,
		&.el-lbl-financial-planning-services,
		&.el-lbl-working-with-us-services {
		  margin-left: 50px;
		}
	  }
  
	  input {
		&[type="radio"],
		&[type="checkbox"] {
		  margin-right: 8px;
		}
  
		&::placeholder {
		  /* Chrome, Firefox, Opera, Safari 10.1+ */
		  color: $-form-field-placeholder-text-color;
		  opacity: 1; /* Firefox */
		}
  
		&:-ms-input-placeholder {
		  /* Internet Explorer 10-11 */
		  color: $-form-field-placeholder-text-color;
		}
  
		&::-ms-input-placeholder {
		  /* Microsoft Edge */
		  color: $-form-field-placeholder-text-color;
		}
	  }
  
	  input[type="text"],
	  input[type="number"],
	  input[type="email"],
	  input[type="password"],
	  textarea,
	  select.form-control {
		border: 1px solid $-form-field-border-color;
		color: $-form-field-caption-text-color;
		display: block;
  
		@media (min-width: $screen-xs-max) {
		  width: auto;
		}
  
		+ .Form__Element__ValidationError {
		  position: relative;
  
		  @media (min-width: $screen-xs-max) {
			max-width: 250px;
		  }
  
		  @media (min-width: $screen-sm-max) {
			max-width: 390px;
		  }
  
		  &::after {
			content: "!";
			width: 20px;
			height: 20px;
			line-height: 40px;
			vertical-align: top;
			text-align: center;
			background: #fff;
			color: $-form-field-error-color;
			border-radius: 50%;
			margin-left: 10px;
			margin-top: 2px;
			font-weight: 700;
			cursor: pointer;
			position: relative;
			outline: none;
			border: 2px solid $-form-field-error-color;
			line-height: 1em;
			padding: 0;
			display: block;
			position: absolute;
			right: 10px;
			top: -39px;
			font-size: 16px;
			padding: 0;
		  }
		}
	  }
  
	  select.form-control {
		+ .Form__Element__ValidationError {
		  &::after {
			right: 20px;
		  }
		}
	  }
	}
  }
  