.btn {
    font-family: "effra", sans-serif;
    padding: 10px 35px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 0;
    text-align: center;
    border: none;
    /*word-break: break-word;
    white-space: pre-line;
    width: 100%;*/
    @media(min-width: $screen-md-min) {
        width: auto;
        white-space: inherit;
    }

    @media(min-width: $screen-lg) {
        min-width: 175px;
    }

    & + .btn {
        @media (max-width: $screen-sm-max) {
            margin-top: 15px;
        }
    }

    @media (min-width: $screen-sm-min) {
        padding: 9px 15px;
    }

    &-primary, &-submit, &-default, &--download {
        @include button-uk($c-button-light-blue, $imgPath);
    }

    &--lock {
        @include button-uk($c-lock-btn-Bgcolour, $imgPath + "btn-lock-uk.png");
        color: $c-button-light-blue !important;
        padding-right: 40px !important;

        &:before {
            content: "";
            background: url(/assets/images/btn-lock-uk.png) no-repeat 30% 20%;
            position: absolute;
            right: 0;
            background-size: 60%;
            z-index: 19;
            display: inline-block;
            height: 25px;
            width: 23px;
        }

        &:after {
            border-color: $c-midnight $c-midnight $c-midnight transparent !important;
            border-width: 0px 20px 110px 40px !important;
        }

        &:hover:after {
            width: 132%;
        }
    }

    &:hover {
        text-decoration: none;
    }
}

a.blog__author-email,
a.btn--widget-email,
.calculator-widget a.btn {
    @extend .btn;
    @include button-uk($c-button-light-blue, $imgPath);
    color: white;
}

/*a.blog__author-email {
    color: $c-moonlight-teal;

    &:hover {
        color: white;
    }
}*/


a.btn {
    text-decoration: none;

    .html-block & {
        color: $c-white !important;
    }
}

// Button on top of a button in mobile
a.btn + a.btn {
    @media (max-width: $screen-sm-max) {
        margin-top: 15px;
    }
}

// Button on top of a button in ul li on mobile
ul.button-list li {
    @media (max-width: $screen-sm-max) {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;

        a {
            width: auto;
        }
    }
}

.btn--download, .btn-default, .btn-primary, .btn-submit {
    color: $c-white;
    background: $c-button-light-blue;
    position: relative;
    overflow: hidden;
    font-family: effra,sans-serif;
    z-index: 0;
    text-align: center;
}

.btn--lock {
    color: $c-moonlight-teal;
    background: $c-white;
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    font-family: effra,sans-serif;
    z-index: 0;
    text-align: center;
    color: #006385 !important;
    padding-right: 40px !important;
}

.btn-action-gif {
	color: $c-moonlight-teal;
	/*background: $c-white;*/
	z-index: 1;
	text-align: center;
	font-size: 40px;
	position: absolute;
	top:15px;
	right:50px;
	border: transparent;
	background-color: transparent;

	.action {
		display:none;
	}
}

.main-content .blog .blog__author.profile-details-email:focus, .main-content .blog .blog__author.profile-details-email:hover, .main-content .blog .blog__author.profile-details-linkedin:focus, .main-content .blog .blog__author.profile-details-linkedin:hover, .main-content .blog .blog__author.profile-details-tel:focus, .main-content .blog .blog__author.profile-details-tel:hover, .main-content .blog .blog__author.profile-details-twitter:focus, .main-content .blog .blog__author.profile-details-twitter:hover, .main-content .blog__author-email:focus, .main-content .blog__author-email:hover, .main-content .blog__author-linkedin:focus, .main-content .blog__author-linkedin:hover, .main-content .blog__author-tel:focus, .main-content .blog__author-tel:hover, .main-content .blog__author-twitter:focus, .main-content .blog__author-twitter:hover, .main-content .full-width .blog .blog__author.profile-details-email:focus, .main-content .full-width .blog .blog__author.profile-details-email:hover, .main-content .full-width .blog .blog__author.profile-details-linkedin:focus, .main-content .full-width .blog .blog__author.profile-details-linkedin:hover, .main-content .full-width .blog .blog__author.profile-details-tel:focus, .main-content .full-width .blog .blog__author.profile-details-tel:hover, .main-content .full-width .blog .blog__author.profile-details-twitter:focus, .main-content .full-width .blog .blog__author.profile-details-twitter:hover, .main-content .full-width .blog__author-email:focus, .main-content .full-width .blog__author-email:hover, .main-content .full-width .blog__author-linkedin:focus, .main-content .full-width .blog__author-linkedin:hover, .main-content .full-width .blog__author-tel:focus, .main-content .full-width .blog__author-tel:hover, .main-content .full-width .blog__author-twitter:focus, .main-content .full-width .blog__author-twitter:hover {
    text-decoration: none;
}

.html-block a.blog__author-email, .html-block a.btn, .html-block a.btn--widget-email:hover {
    text-decoration: none;
}
