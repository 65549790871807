﻿.rss-feed {
    &__container {
        border: 2px solid $c-moonlight-teal;
        padding: 15px 40px;
        margin-bottom: 30px;

        h3 {
            font-size: 28px;
        }
    }

    &__container .itemWrapper{
            margin: 20px 0;
    }



    &__subheading {
        color: $c-moonlight-teal;
        margin-bottom: 2px;

        strong {
            padding-right: 5px;
        }

        &--link {
            position: relative;

            a {
                border-bottom: 1px solid #789ab5;
                font-size: 17px;
                padding-bottom: 5px;
                color: #789ab5;

                &:hover {
                    text-decoration: none;
                }
            }

            &:before {
                font-family: 'Glyphicons Halflings';
                content: "\e008";
                border: 2px solid $c-moonlight-teal;
                color: #789ab5;
                border-radius: 100%;
                padding: 5px;
                display: inline-block;
                height: 50px;
                width: 50px;
                text-align: center;
                font-size: 25px;
                margin-right: 20px;
                position: relative;
                top: 10px;
            }

            &:after {
                content: "";
                width: 21px;
                height: 9px;
                background: url(/assets/images/pagination-next.png) no-repeat 0 50%;
                display: inline-block;
                margin-left: 5px;
                padding-left: 22px;
                /*transition: margin-left .15s ease-in-out;*/
            }

            &:hover:after {
                margin-left: 10px;
                transition: margin-left .15s ease-in-out;
            }
        }

        a.btn.btn-primary {
            margin: 10px 0;
        }
    }
}
