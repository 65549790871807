﻿#cookie-bar {
    background-color: $c-silver-bullett;
    font-family: $font-family-sans-serif;

    p {
        color: $c-brand-dark-blue;
        font-family: $font-family-sans-serif;
    }

    a {
        color: $c-brand-dark-blue;
        text-decoration: underline;
    }

    .cookie-message {
    }

    .close-btn {

        &:before {
            content: "×";
            color: $c-brand-dark-blue;
            font-family: $font-family-sans-serif;
        }
    }
}
