﻿ul.document li a {
    padding-left: 40px;
    margin-left: -40px;
}

ul.circle,
.full-width--bg ul.circle {
    padding-left: 0;
    margin-top: 35px;

    li {
        @extend .tick;
        color: #101820;
        display: inline-block;
        background: url(/assets/images/list-circle.png) no-repeat 0 0;
        padding-left: 70px;
        min-height: 50px;
        width: 100%;
        margin-bottom: 30px;
        background-size: 45px !important;
        vertical-align: top;
        font-weight: 700;
        padding-top: 12px;

        @media(min-width: $screen-sm-min) {
            width: 32.5%;
            padding-top: 5px;
            padding-right: 40px;
            min-height: 70px;
        }
    }
}

.full-width--bg {
    ul.circle li {
        background: url(/assets/images/list-circle-BW.png) no-repeat 0 0;
        color: #fff;
    }
}

ul.tag li {
    background: url(/assets/images/list-tag--uk.png) no-repeat 0 0;

    a {
        position: relative;

        &:after {
            content: '';
            background: url(/assets/images/left-nav-active_UK.png) no-repeat 15px 50%;
            display: inline-block;
            right: 0;
            top: 0;
        }
    }
}