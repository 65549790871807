﻿.search {

    &__title,
    &__item a {
        
    }

    &__title {
        font-weight: 500;
    }
}
