.primary-nav {

    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
        width: 80%;
    }

    &__link,
    &__link span {
        text-decoration: none;
        @media(min-width: $screen-sm-min){
            font-weight: 600;

        &:focus, &:hover {
            text-decoration: underline;
        }
        }
    }


    &__link {
        @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
            font-size: 13px;
        }
    }

    &__panel {
        min-width: 280px;
    }
}
