.full-width {
	> .container {
		padding: 0 30px;

		@media(min-width: $screen-sm-min) {
			padding: 0 15px;
		}
	}

	&--bg {
		background-size: cover;
		background-repeat: no-repeat;

		.html-block p {
			color: #fff;
		}

		&:before,
		&:after {
			content: '';
			border-color: $c-midnight-rain transparent;
			border-style: solid;
			border-width: 200px 90px 0px 0px;
			height: 0px;
			width: 0px;
			position: absolute;
			top: 0;
			display: none;

			@media(min-width: $screen-sm-min) {
				display: block;
			}
		}

		&:after {
			transform: rotate(180deg);
			right: 0;
			bottom: 0;
			top: auto;
		}

		h2 {
			margin-bottom: 15px;
		}

		& form.form {
			background-color: transparent !important;
			margin-top: 50px;
		}

		ul.circle li,
		p {
			color: white;
		}
	}

	.html-block h1,
	.html-block .h1,
	.html-block h1 span,
	.html-block .h1 span {
		font-family: essonnes-display,serif;
		font-size: 40px;
		line-height: 42px;
		font-weight: 600;
	}


	h2 {
		letter-spacing: normal;
		font-size: 28px;
	}

	&.light .EPiServerForms .Form__MainBody .Form__Element {
		label, p {
			color: $c-darkgrey;
		}
	}

	&.dark .EPiServerForms .Form__MainBody .Form__Element {
		label, p {
			color: $c-white;
		}
	}

	&--lightgrey,
	&--light {
		background-color: $c-silver-bullett;
		background: $c-silver-bullett;

		@media(min-width: $screen-sm-min) {
			background: -moz-linear-gradient(-115deg, rgba(181,204,217,1) 0%, rgba(181,204,217,1) 55%, rgba(94,151,176,1) 55%, rgba(94,151,176,1) 100%);
			background: -webkit-linear-gradient(-115deg, rgba(181,204,217,1) 0%,rgba(181,204,217,1) 55%,rgba(94,151,176,1) 55%,rgba(94,151,176,1) 100%);
			background: linear-gradient(115deg, rgba(181,204,217,1) 0%,rgba(181,204,217,1) 55%,rgba(94,151,176,1) 55%,rgba(94,151,176,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b5ccd9', endColorstr='#5e97b0',GradientType=1 );
		}

		@media(min-width: $screen-md-min) {
			background: -moz-linear-gradient(-115deg, rgba(181,204,217,1) 0%, rgba(181,204,217,1) 67%, rgba(94,151,176,1) 67%, rgba(94,151,176,1) 100%);
			background: -webkit-linear-gradient(-115deg, rgba(181,204,217,1) 0%,rgba(181,204,217,1) 67%,rgba(94,151,176,1) 67%,rgba(94,151,176,1) 100%);
			background: linear-gradient(115deg, rgba(181,204,217,1) 0%,rgba(181,204,217,1) 67%,rgba(94,151,176,1) 67%,rgba(94,151,176,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b5ccd9', endColorstr='#5e97b0',GradientType=1 );
		}

		@media(min-width: $screen-lg) {
			background: -moz-linear-gradient(-115deg, rgba(181,204,217,1) 0%, rgba(181,204,217,1) 78%, rgba(94,151,176,1) 78%, rgba(94,151,176,1) 100%);
			background: -webkit-linear-gradient(-115deg, rgba(181,204,217,1) 0%,rgba(181,204,217,1) 78%,rgba(94,151,176,1) 78%,rgba(94,151,176,1) 100%);
			background: linear-gradient(115deg, rgba(181,204,217,1) 0%,rgba(181,204,217,1) 78%,rgba(94,151,176,1) 78%,rgba(94,151,176,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b5ccd9', endColorstr='#5e97b0',GradientType=1 );
		}

		h2 {
			margin-bottom: 55px;
		}

		p {
			color: #101820;
		}
	}

	&--blue .narrow,
	&--bg .narrow {
		color: white;
		font-weight: 300;
	}
}

.full-width.full-width--lightgrey.col-breakout.full-width--bg{
    background-size: cover;
    background-repeat: no-repeat

}

/*.full-width.col-breakout:first-child {

    @media(min-width: $screen-sm-min) {
        padding-top: 0;
    }
}*/

.full-width--disclaimer {
    background-color: $c-disclaimer-bg;
}
