﻿.pagination, .main-content .pagination {
    padding-left: 0;
    list-style: none;
    margin: 0 auto 35px;
    width: 100%;
    text-align: center;
    display: block;

    &__item {
        display: inline-block;
        vertical-align: middle;
    }

    &__link {
        &.pagination__link--prev,
        &.pagination__link--next {
            content: "";
            background: url($imgPath + "right-chevron_slate-grey.png") no-repeat 10px 0;
            background-size: 40%;
        }

        &.pagination__link--prev {
            transform: rotate(180deg);
        }

        &.pagination__link--next {
            background-position: 10px 3px;
        }
    }
}
