// Miscellaneous WMUK styles that should be given a proper home

.back-to-top {
    background: url(/assets/images/back-to-top-paleBlue-UK.png) 50% 50%;
    cursor: pointer;
    background-size: 72%;
    background-repeat: no-repeat;
    background-color: #4fb3f0 !important;
    border-radius: 100px;
}

.header__logo {
    position: absolute !important;
}

.full-width--disclaimer {
    p {
        color: $c-midnight;
    }
}

.primary-nav_btn-close {
    background-color: $c-midnight;
}

.header-top .tertiary__link {
    font-weight: bold;
    text-decoration: none;
}

.com-container {
    padding: 50px 0 0;
}

.risk-warning {
    background-color: $c-grey-opaque;
    padding: 30px;
    margin-bottom: 30px;

    p,
    a {
        color: #fff;
    }
}
