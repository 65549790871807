.main-content .document-downloads__view,
.document-downloads__view,
.html-block .document-downloads__view {
    color: $c-paleblue;
    font-weight: 700;
    box-shadow: 0 2px 0 -1px $c-paleblue;
    padding-bottom: 2px;


    &:after {
        content: ">";
        background: none;
        padding-left: 12px;
        color: $c-paleblue;
        margin-left: 10px;
    }
}

.document-downloads__link,
.html-block .document-downloads__link,
.main-content .document-downloads__link {
    padding: 27px 40px 27px 114px;
    font-family: $font-family-sans-serif;
    background: #f3f3f1 url(/assets/images/list-document--uk.png) no-repeat 20px 15px;
    color: $c-moonlight-teal;
    font-weight: 500;
}
