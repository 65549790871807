﻿.footer-social-links {
    list-style-type: none;

    &--item {
        display: inline-block;
        position: relative;
        width: 33px;
    }
}

.footer-bottom {
    background-color: $footer-bottom-background-color;
    color: $c-midnight-moon;
    font-weight: 500;

    .tertiary__link {
        color: $c-midnight-moon;
        font-weight: 500;
    }

    .tertiary.pull-right {
        @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
            float: left !important;
        }
    }



    .container .pull-left {
        font-weight: 500;
    }

    .tertiary__item:not(:last-child):after {
        font-weight: 500;
    }
}

.footer-links__link {
    color: $c-footer-link;

    &:hover, &:focus {
        color: $c-footer-link;
        text-decoration: underline;
    }
}

.social-icons {
	width: 20px;
	position: relative;
	height: 40px;
	padding: 5px 10px;


	&:hover {
		text-decoration: none;
	}

	&--twitter:after {
		@include fa-icon($fa-var-twitter, 24px, "brands");
		color: #fff;
	}

	&--youtube:after {
		@include fa-icon($fa-var-youtube, 24px, "brands");
		color: #fff;
	}

	&--linkedin:after {
		@include fa-icon($fa-var-linkedin, 24px, "brands");
		color: #fff;
	}

	&--facebook:after {
		@include fa-icon($fa-var-facebook, 24px, "brands");
		color: #fff;
	}

	&--instagram:after {
		@include fa-icon($fa-var-instagram, 24px, "brands");
		color: #fff;
	}

	&--x-twitter:after {
		@include fa-icon($fa-var-x-twitter, 24px, "brands");
		color: #fff;
	}
}
/*&.twitter {

            & a:after {
                font-size: 24px;
                font-weight: 900;
                font-family: Font Awesome\ 5 Free;
                font-style: normal;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                display: inline-block;
                font-variant: normal;
                line-height: 1;
                content: "\f099";
                color: #fff;
            }
        }

        &.linkedin {

            & a:after {

                
            }
        }

        &.youtube {

            & a:after {

                @include fa-icon($fa-var-twitter, 24px, "brands");
                color: #fff;
            }
        }
    }
}



.icon-twitter {
    &:after {
        color: white;
        @include fa-icon($fa-var-twitter, 24px, "brands");
    }
}*/
