//
// Base -> Variables
//
// Use this file to customise Bootstrap variables
// ahead of compilation.
// (See vendor/bootstrap/_variables.scss)
// --------------------------------------------------

// Tables
$table-border-color: #939597; // $neutral-grey-80
$table-cell-padding: 12px 8px;
