.header {
    .site-search {
        @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
            width: 0%;
            top: 3px !important;
        }

        &__btn {

            @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
                background-position: 100% 0;
                width: 25px;
                right: 10px;
            }
        }

        &__input {
            background: #231f20;
        }
    }
}


.header-top__info {
   > p {
        color: #fff !important;
    }
    &.anchor-align {
        text-decoration: none;
        color: #fff;

        &:hover {
            color: inherit;
        }
    }
}

.header-top__info a.anchor-align, .header-bottom span.language-selector__selected {
    font-family: effra,sans-serif;
}

.language-selector__list li input.language-selector__link {
    font-family: effra,sans-serif;
}