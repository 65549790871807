.professionals {

  $root: '.professionals';
  
  $space-xs: 10px;
  $space-sm: 15px;
  $space-md: 20px;
  $space-lg: 30px;
  $space-xl: 40px;

  $color-light-blue: #00B5EF;
  $color-light-grey: #DEDEDF;
  $color-mid-grey: #61666B;
  $color-dark-grey: #333;
  $color-error-red: #FF0000;

  @mixin globe-icon() {
    content: url(/assets/images/globe-icon.svg);
    display: block;
    width: 20px;
    height: 20px;
  }

  @mixin link-arrow() {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 8px;
    height: 8px;
    transform: translateY(-50%) rotate(45deg);
    border-right: 2px solid $c-slate;
    border-top: 2px solid $c-slate;
  }

  @mixin close-icon() {
    height: 40px;
    width: 40px;

    &::before,
    &::after {
      position: absolute;
      right: 4px;
      content: " ";
      height: 16px;
      width: 2px;
      bottom: 9px;
      background-color: $color-dark-grey;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  .space {

    &__btm {
      &--sm {
        margin-bottom: $space-sm;
      }
      &--md {
        margin-bottom: $space-md;
      }
      &--lg {
        margin-bottom: $space-lg;
      }
      &--xl {
        margin-bottom: $space-xl;
      }
    }

    &__top {
      &--sm {
        margin-top: $space-sm;
      }
      &--md {
        margin-top: $space-md;
      }
      &--lg {
        margin-top: $space-lg;
      }
      &--xl {
        margin-top: $space-xl;
      }
    }

    &__vertical {
      &--sm {
        margin-top: $space-sm;
        margin-bottom: $space-sm;
      }
      &--md {
        margin-top: $space-md;
        margin-bottom: $space-md;
      }
      &--lg {
        margin-top: $space-lg;
        margin-bottom: $space-lg;
      }
      &--xl {
        margin-top: $space-xl;
        margin-bottom: $space-xl;
      }
    }
  }

  &__link-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 100%;

    li {
      padding: $space-sm 0;
      width: 100%;

      &:not(:first-child) {
        border-top: 1px solid $color-light-grey;
      }

      a {
        width: 100%;
        display: block;
      }
    }

    @media(min-width: $screen-sm-min) {
      flex-direction: row;
      align-items: center;
      width: auto;
    
      li {
        margin: 0;
        padding: 0;
        width: auto;
      
        &:not(:first-child) {
          margin-left: $space-xs;
          border-top: none;
        }

        a {
          width: auto;
        }
      }
    }

    &--inline {
      flex-direction: row;
      align-items: center;

      li {
        border-top: none !important;
        width: auto;

        @media(max-width: $screen-sm-min) {
          width: 50%;
          text-align: center;
        }

        a {
          width: auto;
        }
      }
    }

    &--spaced {
      li:not(:first-child) {
        margin-left: $space-lg;
      }
    }

    &--left {
      justify-content: start;
    }

    &--right {
      justify-content: end;
    }
  }

  &__text {

    &--medium {
      font-weight: 700;
      line-height: 20px;
    }

    &--strong {
      font-weight: bold;
    }

    &--link {
      color: $c-button-light-blue;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      position: relative;
  
      &:hover {
        color: $c-button-light-blue;
        text-decoration: underline;
      }

      &-mobile {
        @media(max-width: $screen-sm-min) {

          color: $color-mid-grey;

          &::after {
            @include link-arrow();
          }

        }
      }
    }
  }

  &__quick-links {
    position: relative;
    height: 100%;

    &-container {
      text-align: left;
      padding: 0;
      
      @media(min-width: $screen-sm-min) { 
        padding: $space-md $space-xl;
        background-color: white;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &-heading {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 34px;
      color: $c-moonlight-teal;
      padding-right: $space-xl;
    }

    &-list {
      list-style: none;
      width: auto;
      padding: 0;

      li {
        padding: $space-xs 0;
        width: 100%;

        @media(min-width: $screen-sm-min) {
          border-bottom: 1px solid $color-light-grey;
        }
  
        a {
          @media(min-width: $screen-sm-min) {
            width: 100%;
            display: block;
            position: relative;
            color: $c-slate;
            font-weight: 700;
            background-color: white;
            text-align: left;
            padding: 0 $space-xl 0 0;

            &:focus,
            &:active,
            &:active:hover,
            &:hover {
              background: white;
              color: $c-slate;

              &::after {
                content: none;
                display: none;
              }
            }

            &:hover {
              span {
                text-decoration: underline;
              }
            }

            &::before {
              @include link-arrow();
              right: 2px;
            }

            &::after {
              content: none;
              display: none;
            }
          }
        }
      }
    }
  }

  &__sticky-nav {
    background: #F4F4F4;
    height: 60px;
    width: 100%;
    position: relative;


    &.sticky {
      position: fixed;
      top: 0;
      z-index: 98; // don't float over mobile nav or modal

      @media(min-width: $screen-sm-min) {
        z-index: 100;
      }
    }
 
    &-links {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
    }

    &-link {
      padding: 0 $space-xs;
      text-align: center;

      &:first-child {
        padding-right: $space-xs;
        padding-left: 0;
      }

      &:last-child {
        padding-left: $space-xs;
        padding-right: 0;
      }

      @media(min-width: $screen-sm-min) { 
        padding: 0 $space-lg;

        &:first-child {
          padding-right: $space-lg;
          padding-left: 0;
        }
  
        &:last-child {
          padding-left: $space-lg;
          padding-right: 0;
        }
      }

      a {
        position: relative;
        color: black;

        &.active {
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 5px;
            background-color: $color-light-blue;
            position: absolute;
            bottom: -21px;
            left: 0;
          }
        }
      }
    }
  }

  &__modal {

    &-close {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      height: 16px;
      margin-bottom: $space-xs;  

      @media(min-width: $screen-sm-min) {
        height: 0;
        padding: 0;
        margin: 0;
        overflow: visible;
      }
    }

    &-close-icon {
     @include close-icon();

     @media(min-width: $screen-sm-min) {
      position: absolute;
      top: -70px;
      right: -62px;

      &::before,
      &::after {
        right: 18px;
        background-color: white;
      }
     }
    }

    &-step {
      display: none;

      &--alert {
        text-align: center;
      }
  
      &.active {
        display: block;
      }
    }
  
    &-content {
      max-width: 500px;
      margin: 0 auto;
    }
  
    &-cta-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
  
      #{$root}__form--checkbox {
        margin: $space-md 0;
      }
  
      @media(min-width: $screen-sm-min) {
        flex-direction: row;
        align-items: center;
  
        #{$root}__form--checkbox {
          margin: $space-md 0;
        }

        .btn-default {
          width: auto !important;
        }
      }
    }
  
    &-audience-options-container {
      #{$root}__form--radio-button {
        &:last-child {
          margin-bottom: $space-md;
    
          @media(min-width: $screen-sm-min) {
            margin-bottom: $space-xl;
          }
        }
      }
    }
  
    &-audience-options {
      display: none;
      &.active {
        display: block;
      }
    }
  
    &-footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      border-top: 1px solid $color-light-grey;
      margin-top: $space-xl;
      padding-top: $space-md;
  
      @media(min-width: $screen-sm-min) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    &-back-cta {
      position: relative;
      padding-left: $space-sm;
      margin-bottom: $space-lg;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
  
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 8px;
        height: 8px;
        transform: translateY(-50%) rotate(45deg);
        border-left: 2px solid $c-slate;
        border-bottom: 2px solid $c-slate;
      }
    }
  }

  &__bar {
    top: 0;
    width: 100%;
    height: 0;
    z-index: 100;
    overflow: hidden;
    position: relative;

    // allow professionals location/audience selector modal to sit above mobile nav
    + .header {
      .primary-nav {
        @media(max-width: $screen-md-min) {
          z-index: 99;
        }
      }
    }

    @media(min-width: $screen-sm-min) {
      height: 40px;
      background: $c-button-light-blue;
      color: white;
      overflow: visible;
    }

    &-mobile-nav {
      display: block;
      padding: 0 $space-md;
      margin-bottom: 0px;
      margin-right: $space-md;
      padding-top: $space-md;
      max-width: 90%;
      color: white;
      background: url(/assets/images/nav-dropdown-uk-mobile.png) no-repeat 100% 50%;
      cursor: pointer;

      &::before {
        @include globe-icon;
        margin-bottom: $space-xs;
      }

      @media(min-width: $screen-sm-min) {
        display: none;
      }
    }

    &-outer-container {
      height: 100%;
      cursor: pointer;

      &.active {
        + #{$root}__bar-dropdown {
          display: block;
        }

        #{$root}__bar-arrow-icon {
          &::after {
            transform: translateY(-50%) rotate(-135deg);
          }
        }
      }
    }
        
    &-inner-container {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      max-width: 940px;
      margin: 0 auto;
      padding: 0 $space-lg;

      @media(min-width: $screen-md-min) {
        padding: 0;
      }

      @media(min-width: $screen-lg-min) {
        max-width: 1140px;
      }
    }

    &-arrow-icon {
      height: 20px;
      width: 20px;
      position: relative;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 8px;
        height: 8px;
        transform: translateY(-50%) rotate(45deg);
        transition: transform 0.5s ease;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
      }
    }

    &-location,
    &-audience-type {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-location {
      padding-right: $space-xs;
      padding-left: $space-lg;
      border-right: 2px solid white;
      position: relative;

      &::before {
        @include globe-icon;
        position: absolute;
        left: 0;
      }
    }

    &-audience-type {
      padding-left: $space-xs;
      position: relative;
      max-width: 80%;

      @media(min-width: $screen-lg-min) {
        max-width: none;
      }
    }

    &-dropdown {
      background-color: white;
      display: none;
      padding: 0 $space-lg;
      z-index: 1000;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      @media(max-width: $screen-sm-min) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
      }

      @media(min-width: $screen-sm-min) {
        padding: 0;
        background-color: $c-moonlight-teal;
      }

      #{$root}__form--error {
        margin-bottom: $space-sm;
        color: white;
      }

      &-close {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
        height: 16px;
        margin-top: $space-lg;
        margin-bottom: $space-xs;  

        @media(min-width: $screen-sm-min) {
          display: none;
        }
      }

      &-close-icon {
       @include close-icon();
      }

      &-container {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0 0 $space-lg;

        @media(min-width: $screen-sm-min) {
          flex-direction: row;
          padding-left: $space-lg;
          padding-right: $space-lg;
          padding-bottom: 0;
          padding-top: $space-lg;
          max-width: 930px;
        }

        @media(min-width: $screen-md-min) {
          padding-left: 0;
          padding-right: 0;
        }

        @media(min-width: $screen-lg-min) {
          max-width: 1140px;
        }
      }

      &-location,
      &-audience-type {
        width: 100%;
        padding: 0;

        @media(min-width: $screen-sm-min) {
          width: 50%;
        }
      }

      &-location {
        @media(min-width: $screen-sm-min) {
          padding-right: $space-sm;
        }
      }

      &-audience-type {
        padding-top: $space-md;

        @media(min-width: $screen-sm-min) {
          padding-left: $space-sm;
          padding-top: 0;
        }
      }

      &-remember-selection {
        position: absolute;
        bottom: 0;
        margin-top: $space-md;

        @media(min-width: $screen-sm-min) {
          position: relative;
        }
      }

      &-footer {
        display: flex;
        flex-direction: column;
        align-items: start;
        border-top: 1px solid white;
        margin-top: $space-xl;
        padding-top: $space-md;
        padding-bottom: $space-md;
        margin: $space-xs auto 0;
        justify-content: flex-start;
    
        @media(min-width: $screen-sm-min) {
          margin-top: $space-lg;
          padding-left: $space-lg;
          padding-right: $space-lg;
          flex-direction: row;
          align-items: center;
          max-width: 930px;
        }

        @media(min-width: $screen-md-min) {
          padding-left: 0;
          padding-right: 0;
        }
          
        @media(min-width: $screen-lg-min) {
          max-width: 1140px;
        }

        &--label {
          padding-right: $space-md;
          color: $color-mid-grey;

          @media(min-width: $screen-sm-min) {
            color: white;
          }
        }
      }
    }
  }
  

  &__form {

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid $color-mid-grey;
      background-color: white;
    }

    button {
      border: none;
      background: none;

      &.btn.btn-default {
        position: relative;
        background: $color-light-blue;
        color: white;
      }
    }

    label {
      margin: 0;
    }

    a:not(.btn):not(.professionals__text--link-mobile) {
      color: $c-button-light-blue;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      position: relative;
    }

    &--heading {
      font-size: 24px;
      line-height: 28px;
      font-weight: 400;
      color: $c-moonlight-teal;

      @media(min-width: $screen-sm-min) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    &--terms-conditions {
      width: 100%;
      height: auto;
      padding: $space-md;
      border:  1px solid $color-mid-grey;
      height: 250px;
      overflow-y: scroll;
    }

    &--field {
      display: flex;
      align-items: center;
      min-height: 48px;
      width: 100%;
      padding: 10px;
      cursor: pointer;
      font-size: 16px;
      color: #231F20;
      font-weight: 700;
    }

    &--styled-select {

      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: $space-md;
        width: 10px;
        height: 10px;
        transform: translateY(-50%) rotate(45deg);
        border-right: 2px solid $color-mid-grey;
        border-bottom: 2px solid $color-mid-grey;
      }
    }

    &--radio-button {

      margin-bottom: $space-md;

      label {
        border: 1px solid $color-mid-grey;
        margin-bottom: 0;
        justify-content: center;
        background-color: white;

        &:hover {
          background-color: #F4F4F4;
        }
      }

      input { 
        display: none;

        &:checked {
        
          + label {
            background-color: $c-moonlight-teal;
            border-color: $c-moonlight-teal;
            color: white;
          }
        }
      }
    }

    &--checkbox {

      input {
        display: none;

        &:checked {

          + label {

            &::after {
              content: '';
              content: "";
              left: 7px;
              top: 3px;
              width: 6px;
              height: 12px;
              transform: rotate(45deg);
              border-right: 2px solid black;
              border-bottom: 2px solid black;
              position: absolute;
            }
          }
        }
      }

      label {

        position: relative;
        padding-left: $space-lg;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        color: $color-mid-grey;

        &::before {
          content: '';
          content: "";
          width: 20px;
          height: 20px;
          display: block;
          border: 1px solid black;
          position: absolute;
          left: 0;
        }
      }
    }

    &--error {
      display: none;
      color:  $color-error-red;
      margin-top: $space-sm;

      &.show {
        display: block;
      }
    }

    &--dark {

      @media(min-width: $screen-sm-min) {

        p {
          color: white;
        }
  
        #{$root}__text--link-mobile {
          @media(max-width: $screen-sm-min) {
  
            color: $color-light-blue;
  
            &::after {
              border-right: 2px solid $color-light-blue;
              border-top: 2px solid $color-light-blue;
            }
  
          }
        }
  
        #{$root}__form {
  
          &--heading {
            color: white;
          }
  
          &--radio-button {
      
            input { 
      
              &:checked {
              
                + label {
                  background-color: $color-light-blue;
                  border-color: $color-light-blue;
                  color: white;
                }
              }
            }
          }
  
          &--checkbox {
  
            label {
              color: white;
      
              &::before {
                border: 1px solid white;
                background-color: white;
              }
            }
          }
        }

      }

    }
  }
}