.language-selector {

    @media(min-width: $screen-xs-max) and (max-width: 991px) {
        width: 18%;
    }

    &__selected {

        @media(min-width: $screen-xs-max) and (max-width: 991px) {
            padding: 12px 0px 20px 29px;
        }
    }
}
