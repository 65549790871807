.form-consultation-vct.full-width {
    form.form {
        background: transparent;
        padding-top: 0;
        height: auto;
        padding-bottom: 0;
        margin-bottom: -16px;

        p.consent {
            padding: 0 t-spacing(11);
            margin-bottom: -32px;
        }

        .FormTextbox--Textarea > label,
        .Form__Element > label.Form__Element__Caption label,
        .Form__Element .Form__Element__Caption {
            display: none;
        }

        .FormChoice label,
        .Form__MainBody .Form__Element label,
        .Form__MainBody .Form__Element p {
            color: #fff;
        }

        input[type='checkbox'] {
            margin-bottom: t-spacing(4);
            float: left;
            margin-right: t-spacing(4);
        }

        a.privacy {
            color: #fff;
            text-decoration: underline;
            font-weight: bold;
        }

        input[type='email'],
        input[type='number'],
        input[type='password'],
        input[type='text'],
        select.form-control,
        textarea {
            border: none;
            border-radius: 4px;
        }

        .FormChoice label {
            margin-bottom: 0;
        }
        @media (max-width: $screen-lg) {
            padding: t-spacing(4);
            h2 {
                font-size: $font-size-large;
                line-height: 1.42857;
                font-weight: 700;
            }
            button {
                min-width: 110px;
            }
        }
    }

    @media (max-width: $screen-lg) {
        h2.consultation {
            font-size: 79px;
        }
    }

    @media (max-width: $screen-md-max) {
        p.consent {
            padding: 0 t-spacing(5);
        }
    }

    @media (max-width: $screen-xs-max) {
        h2.consultation {
            font-size: 46px;
            padding: 0 t-spacing(4);
        }
    }

    h2.consultation {
        font-size: 66px;
        margin-top: 10%;
        line-height: 1;
        font-family: essonnes-display, serif;
    }
    span.text-left,
    span.text-right {
        display: block;
        font-size: 32.5px;
    }

    span.text-right {
        margin-top: t-spacing(4);
    }

    .col-breakout {
        margin-top: t-spacing(14);
    }
}
