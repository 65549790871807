﻿.accordion__body {
    background-color: white;
}

.accordion__toggle {
    font-family: $font-family-sans-serif;
    font-size: 20px;

    &:after,
    &.collapsed:after {
        content: "";
        background: url(/assets/images/nav-dropdown-uk.png) no-repeat 0 0;
        width: 15px;
        height: 10px;
        background-size: contain;
        top: 35px;
        right: 40px;
    }

    &:after {
        transform: rotate(180deg) !important;
    }

    &.collapsed:after {
        transform: rotate(0deg) !important;
    }
}


//Bordered accordion

.bordered-accordion {

    .accordion__toggle {
        border: 2px solid #005775;
        background-color: white;
        color: #005775;
        font-size: 25px;
        font-weight: 500;
        border-radius: 0;
        background-size: contain;


        &.collapsed:after,
        &:after {
            width: 20px;
            height: 15px;
            background-size: contain;
            top: 35px;
            background: url(/assets/images/accordion-arrow-down-moonlight.png) no-repeat 0 0 !important;
        }

    &:after {
        transform: rotate(180deg) !important;
    }

    &.collapsed:after {
        transform: none !important;
    }
    }
}
