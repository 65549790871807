table.table.table-vct {
    tbody {
        tr,
        th {
            border-bottom: solid 1px;
            border-top: none;
        }
        td {
            border: none;
        }
    }

    @media (max-width: $screen-sm-max) {
        font-size: 12px;
        tbody {
            td,
            th {
                padding: t-spacing(2) t-spacing(1);
            }
        }
    }
}
